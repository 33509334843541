import { makeAutoObservable, observable, runInAction } from 'mobx';
import { User } from 'protos/pb/v1alpha1/user';
import {
  LoginResponse,
  LogoutRequest,
  SingleSignOnRequest,
} from 'protos/pb/v1alpha1/users_service';
import { authService } from '../services/AuthService';
import {
  Token,
  convertToken,
  storageService,
} from '../services/StorageService';
import { AuthPlatform } from '../utils/enums';
import { RootStore } from './store';

class AuthStore {
  rootStore: RootStore;
  @observable isLoading = false;
  @observable error?: string = undefined;
  @observable user?: User = undefined;
  @observable token?: Token = undefined;
  @observable loginErrorMessage?: string = undefined;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  toggleLoading = () => {
    runInAction(() => {
      this.isLoading = !this.isLoading;
    });
  };

  setLoginErrorMessage = (payload?: string) => {
    runInAction(() => {
      this.loginErrorMessage = payload;
    });
  };

  resetUserAndToken = () => {
    runInAction(() => {
      this.user = undefined;
      this.token = undefined;
    });
  };

  setLoggedInUser = (user: User) => {
    runInAction(() => {
      this.user = user;
    });
  };

  // NOTE: This function is not used only for testing purposes in local environment. This will not be used in deployed app.
  loginWithEmailPassword = async () => {
    runInAction(() => {
      this.isLoading = true;
      this.error = undefined;
    });
    try {
      const loginResponse: LoginResponse = await authService.login({
        email: 'sughosh@orby.ai',
        password: 'password',
      });

      const token = convertToken(loginResponse);
      await storageService.setStoredToken(token);
      runInAction(() => {
        this.user = loginResponse.user;
        this.token = token;
        this.isLoading = false;
      });
    } catch (e: any) {
      runInAction(() => {
        this.loginErrorMessage = e.errors?.[0]?.message || e?.message;
        this.isLoading = false;
      });
    }
  };

  googleLogin = async (payload: { scope: string }) => {
    runInAction(() => {
      this.error = undefined;
    });
    try {
      const CLIENT_ID = encodeURIComponent(
        process.env.REACT_APP_CLIENT_ID as string,
      );

      const response: {
        code: string;
        scope?: string;
        error?: string;
        error_description?: string;
        error_uri?: string;
        state?: string;
      } = await new Promise((resolve) => {
        // @ts-ignore
        const client = google.accounts.oauth2.initCodeClient({
          client_id: CLIENT_ID,
          scope: payload.scope,
          ux_mode: 'popup',
          redirect_uri: process.env.REACT_APP_REDIRECT_URI,
          include_granted_scopes: true,
          callback: (response: any) => {
            resolve(response);
          },
        });
        client.requestCode();
      });

      const singleSignOnRequest: SingleSignOnRequest = {
        googleAuthorizationCode: response.code,
      };
      await this.googleAuthLogin(singleSignOnRequest);
    } catch (e: any) {
      runInAction(() => {
        this.loginErrorMessage = e.errors?.[0]?.message || e?.message;
      });
    }
  };

  googleAuthLogin = async (payload: SingleSignOnRequest) => {
    runInAction(() => {
      this.isLoading = true;
      this.error = undefined;
    });
    try {
      const loginResponse: LoginResponse =
        await authService.googleLogin(payload);

      const token = convertToken(loginResponse);
      token.platform = AuthPlatform.GOOGLE;
      await storageService.setStoredToken(token);
      if (loginResponse?.user?.googleToken?.accessToken) {
        await storageService.setStoredGoogleToken({
          accessToken: loginResponse.user.googleToken.accessToken,
          accessTokenExpiresAt: loginResponse.user.googleToken.expiry!,
        });
      }
      runInAction(() => {
        this.user = loginResponse.user;
        this.token = token;
        this.isLoading = false;
      });
    } catch (e: any) {
      runInAction(() => {
        this.loginErrorMessage = e.errors?.[0]?.message || e?.message;
        this.isLoading = false;
      });
    }
  };

  logoutUser = async (payload: LogoutRequest) => {
    try {
      await authService.logoutUser(payload);
      await storageService.deleteStoredValues();
      runInAction(() => {
        this.token = undefined;
        this.user = undefined;
      });
      this.rootStore.userStore.reset();
    } catch (e: any) {
      runInAction(() => {
        this.error = e?.errors[0]?.message || e?.message;
      });
    }
  };
}

export default AuthStore;
