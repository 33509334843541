import { Close } from '@mui/icons-material';
import {
  Button,
  DialogActions,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
} from '@mui/material';
import CustomTypography, {
  TypographyType,
} from '../../../components/core/CustomTypography';
import { useStore } from '../../../hooks/useStore';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { toastService } from '../../../services/ToastService';
import EmailsAdditionComponent from './components/EmailsAdditionComponent';
import WorkflowTemplatesSelector from './components/WorkflowTemplatesSelector';
import { WorkflowTemplateType } from 'protos/common/workflow_common';
import { Hyperparameter } from 'protos/common/hyperparameter';
import HyperparameterSelector from '../components/HyperparameterSelector';
import { Organization } from 'protos/pb/v1alpha1/organization';

const CreateOrganization: React.FC<{ onClose: () => void }> = observer(
  ({ onClose }) => {
    const store = useStore();
    const { createOrganization } = store.internalAppStore;
    const [selectedTemplates, setSelectedTemplates] = useState<
      WorkflowTemplateType[]
    >([]);
    const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
    const [selectedAdmins, setSelectedAdmins] = useState<string[]>([]);
    const [orgDisplayName, setOrgDisplayName] = useState<string>('');
    const isButtonEnabled =
      orgDisplayName.trim() !== '' && selectedTemplates.length > 0;
    const [selectedHyperparameter, setSelectedHyperparameter] =
      useState<Hyperparameter>({});

    const handleCreateOrganization = () => {
      if (orgDisplayName.trim() === '') {
        toastService.showError('Organization name cannot be empty');
        return;
      }
      const newOrg: Organization = {
        displayName: orgDisplayName.trim(),
        users: selectedUsers,
        admins: selectedAdmins,
        workflowTemplateTypes: selectedTemplates,
      };
      if (selectedHyperparameter.name) {
        newOrg.hyperparameterResourceName = selectedHyperparameter.name;
      }
      createOrganization({
        organization: newOrg,
      })
        .then(() => {
          setSelectedAdmins([]);
          setSelectedUsers([]);
          setSelectedTemplates([]);
          setOrgDisplayName('');
          toastService.showSuccess('Organization created successfully');
          onClose();
        })
        .catch((e) => {
          toastService.showError(`Failed to create organization: ${e}`);
          onClose();
        });
    };

    return (
      <Grid container spacing={2} sx={{ padding: '16px' }}>
        <DialogTitle>Create Organization</DialogTitle>
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'red',
          }}
        >
          <Close />
        </IconButton>
        <Grid item xs={12}>
          <CustomTypography
            typographyType={TypographyType.Label}
            sx={{ margin: '5px 0' }}
          >
            Organization Name
          </CustomTypography>
          <TextField
            id='orgName'
            variant='outlined'
            value={orgDisplayName}
            onChange={(e) => setOrgDisplayName(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <EmailsAdditionComponent
            addedEmails={selectedUsers}
            setAddedEmails={setSelectedUsers}
            label="Add Users' Emails"
          />
        </Grid>
        <Grid item xs={12}>
          <EmailsAdditionComponent
            addedEmails={selectedAdmins}
            setAddedEmails={setSelectedAdmins}
            label="Add Admins' Emails"
          />
        </Grid>
        <Grid item xs={12}>
          <WorkflowTemplatesSelector
            selectedTemplates={selectedTemplates}
            setSelectedTemplates={setSelectedTemplates}
          />
        </Grid>
        <Grid item xs={12}>
          <HyperparameterSelector
            selectedValue={selectedHyperparameter}
            setSelectedValue={setSelectedHyperparameter}
          />
        </Grid>
        <DialogActions
          sx={{
            justifyContent: 'end',
            width: '100%',
          }}
        >
          <Button
            variant='contained'
            color='primary'
            onClick={handleCreateOrganization}
            disabled={!isButtonEnabled}
          >
            Submit
          </Button>
        </DialogActions>
      </Grid>
    );
  },
);

export default CreateOrganization;
