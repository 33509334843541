import { Empty } from 'protos/google/protobuf/empty';
import {
  OrbyInternalServiceClientImpl,
  CreateAnnouncementRequest,
  CreateAnnouncementResponse,
  GetAnnouncementRequest,
  GetAnnouncementResponse,
  UpdateAnnouncementRequest,
  UpdateAnnouncementResponse,
  ListAnnouncementsRequest,
  ListAnnouncementsResponse,
  DeleteAnnouncementRequest,
} from 'protos/pb/orby_internal/orby_internal_service';
import { storageService } from './StorageService';
import { rpcWithErrorHandling } from '../utils/RpcUtils';

export class AnnouncementService {
  private static instance: AnnouncementService;

  private static client = new OrbyInternalServiceClientImpl(
    rpcWithErrorHandling,
  );

  public static getInstance(): AnnouncementService {
    if (!this.instance) {
      this.instance = new AnnouncementService();
    }
    return this.instance;
  }

  async getAnnouncements(
    req: ListAnnouncementsRequest,
  ): Promise<{ response?: ListAnnouncementsResponse; error?: Error }> {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response = await AnnouncementService.client.ListAnnouncements(req, {
        authorization,
      } as any);
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  }

  async createAnnouncement(
    req: CreateAnnouncementRequest,
  ): Promise<{ response?: CreateAnnouncementResponse; error?: Error }> {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response = await AnnouncementService.client.CreateAnnouncement(
        req,
        {
          authorization,
        } as any,
      );
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  }

  async updateAnnouncement(
    req: UpdateAnnouncementRequest,
  ): Promise<{ response?: UpdateAnnouncementResponse; error?: Error }> {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response = await AnnouncementService.client.UpdateAnnouncement(
        req,
        {
          authorization,
        } as any,
      );
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  }

  async deleteAnnouncement(req: DeleteAnnouncementRequest): Promise<Empty> {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response = await AnnouncementService.client.DeleteAnnouncement(
        req,
        {
          authorization,
        } as any,
      );
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  }

  async getAnnouncement(
    req: GetAnnouncementRequest,
  ): Promise<{ response?: GetAnnouncementResponse; error?: Error }> {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response = await AnnouncementService.client.GetAnnouncement(req, {
        authorization,
      } as any);
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  }
}

export const announcementService = AnnouncementService.getInstance();
