import { MoreVert } from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  Dialog,
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import CustomPagination from '../../../components/core/CustomPagination';
import CustomTableCell from '../../../components/core/CustomTableCell';
import CustomTableLabel from '../../../components/core/CustomTableLabel';
import SlideUpTransition from '../../../components/core/SlideUpTransition';
import StyledTableRow from '../../../components/core/StyledTableRow';
import { useStore } from '../../../hooks/useStore';
import { observer } from 'mobx-react';
import { Organization } from 'protos/pb/v1alpha1/organization';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toastService } from '../../../services/ToastService';
import {
  ACCENT_COLOR_1,
  PERMISSION_MANAGE_USERS,
} from '../../../utils/constants';
import AssignHyperparameterToOrg from './AssignHyperparameterToOrg';
import { ListOrganizationsRequest } from 'protos/pb/orby_internal/orby_internal_service';
import { GridSearchIcon } from '@mui/x-data-grid';

const OrganizationsList: React.FC<{
  setUpdateOrganizationOpenFor: React.Dispatch<
    React.SetStateAction<Organization | undefined>
  >;
}> = observer(({ setUpdateOrganizationOpenFor }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();
  const store = useStore();
  const {
    loadingOrganizationsList,
    organizationsList,
    organizationsListError,
    totalOrganizationsCount,
    getOrganizationsList,
    resetOrganizationsListError,
    hyperparameterNameDisplayNameMap,
  } = store.internalAppStore;
  const { user } = store.authStore;

  const [page, setPage] = useState(1);
  const [assignHyperparameterForOrg, setAssignHyperparameterForOrg] =
    useState<Organization>();
  const rowsPerPage = 10;
  const pagedOrgs = organizationsList?.slice(
    (page - 1) * rowsPerPage,
    page * rowsPerPage,
  );
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedOrganization, setSelectedOrganization] =
    useState<Organization>({});
  const open = Boolean(anchorEl);
  const [displayNamePrefix, setDisplayNamePrefix] = useState('');

  useEffect(() => {
    refreshPage();
  }, []);

  useEffect(() => {
    if (organizationsListError) {
      toastService.showError(
        `Failed to fetch organizations: ${organizationsListError}`,
      );
      resetOrganizationsListError();
    }
  }, [organizationsListError]);

  const fetchNextOrganizations = async (nextPage: number) => {
    getOrganizationsList(
      { pageNumber: nextPage, pageSize: rowsPerPage },
      false /* Refresh */,
    );
  };

  const navigateToOrganizationDetails = (organizationId: string) => {
    navigate(`${organizationId}`);
  };

  useEffect(() => {
    refreshPage();
  }, [displayNamePrefix]);

  const refreshPage = () => {
    setPage(1);
    setSelectedOrganization({});
    const req: ListOrganizationsRequest = {
      pageNumber: page,
      pageSize: rowsPerPage,
    };
    let filterStr = '';
    if (displayNamePrefix) {
      filterStr = `display_name_prefix=${displayNamePrefix}`;
    }
    req.filter = filterStr;
    getOrganizationsList(req, true);
  };

  const getHyperparameter = (hyperparameterName?: string) => {
    if (
      !hyperparameterName ||
      hyperparameterName === 'hyperparameters/000000000000000000000000'
    ) {
      return 'DEFAULT';
    }

    if (hyperparameterNameDisplayNameMap[hyperparameterName]) {
      return hyperparameterNameDisplayNameMap[hyperparameterName];
    }

    return hyperparameterName.split('/').pop();
  };

  return (
    <>
      {assignHyperparameterForOrg && (
        <Dialog
          fullScreen={fullScreen}
          open={!!assignHyperparameterForOrg}
          onClose={() => {
            setAssignHyperparameterForOrg(undefined);
          }}
          TransitionComponent={SlideUpTransition}
        >
          <AssignHyperparameterToOrg
            org={assignHyperparameterForOrg}
            onClose={() => {
              setAssignHyperparameterForOrg(undefined);
            }}
          />
        </Dialog>
      )}

      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'flex-start'}
        width={'100%'}
        gap={'8px'}
        marginTop={'24px'}
        marginBottom={'24px'}
      >
        <Box>
          <TextField
            variant='outlined'
            value={displayNamePrefix}
            size={'small'}
            InputProps={{
              startAdornment: <GridSearchIcon fontSize='medium' />,
              sx: {
                '& .MuiOutlinedInput-input  ': {
                  WebkitTextFillColor: '#000000 !important',
                  height: '23px',
                  lineHeight: '16px',
                },
              },
            }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setDisplayNamePrefix(event.target.value)
            }
          />
        </Box>
      </Box>

      {loadingOrganizationsList ? (
        <Box display={'flex'} pt={'60px'} justifyContent={'center'}>
          <CircularProgress />
        </Box>
      ) : (
        <Box
          sx={{
            marginTop: '30px',
            borderRadius: '10px',
          }}
        >
          <TableContainer
            sx={{
              borderStartStartRadius: 'inherit',
              borderStartEndRadius: 'inherit',
            }}
          >
            <Table
              sx={{
                tableLayout: 'fixed',
              }}
            >
              <TableHead>
                <TableRow
                  sx={{
                    backgroundColor: ACCENT_COLOR_1,
                  }}
                >
                  <CustomTableCell title='Organization Name'>
                    <CustomTableLabel label='Organization Name' />
                  </CustomTableCell>
                  <CustomTableCell title='Hyperparameter'>
                    <CustomTableLabel label='Hyperparameter' />
                  </CustomTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pagedOrgs.map((org, index) => (
                  <StyledTableRow
                    key={org.name}
                    tabIndex={0}
                    role='row'
                    sx={{
                      backgroundColor: index % 2 === 0 ? 'white' : '#f9f9f9',
                      cursor: 'pointer',
                      ':hover': { backgroundColor: '#1669F74D' },
                    }}
                    onClick={() => {
                      if (open) {
                        return;
                      }
                      navigateToOrganizationDetails(
                        org.name?.split('/').pop() ?? '',
                      );
                    }}
                  >
                    <CustomTableCell>
                      <span title={org.displayName ?? 'NO DATA'}>
                        {org.displayName ?? 'NO DATA'}
                      </span>
                    </CustomTableCell>
                    <CustomTableCell
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <span
                        title={getHyperparameter(
                          org.hyperparameterResourceName,
                        )}
                      >
                        {getHyperparameter(org.hyperparameterResourceName)}
                      </span>
                      <span>
                        <IconButton
                          aria-controls={open ? 'long-menu' : undefined}
                          aria-expanded={open ? 'true' : undefined}
                          aria-haspopup='true'
                          onClick={(event: React.MouseEvent<HTMLElement>) => {
                            event.stopPropagation();
                            setAnchorEl(event.currentTarget);
                            setSelectedOrganization(org);
                          }}
                        >
                          <MoreVert />
                        </IconButton>
                      </span>
                    </CustomTableCell>
                  </StyledTableRow>
                ))}
                <Menu
                  autoFocus={false}
                  disableAutoFocusItem={true}
                  aria-hidden={false}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={() => {
                    setAnchorEl(null);
                  }}
                  slotProps={{
                    paper: {
                      style: {
                        boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.15)',
                        borderRadius: '4px',
                      },
                    },
                  }}
                >
                  {user?.permittedActions?.includes(
                    PERMISSION_MANAGE_USERS,
                  ) && (
                    <MenuItem
                      onClick={(e) => {
                        e.stopPropagation();
                        setAssignHyperparameterForOrg(selectedOrganization);
                      }}
                    >
                      Assign Hyperparameter
                    </MenuItem>
                  )}
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      setUpdateOrganizationOpenFor(selectedOrganization);
                    }}
                  >
                    Edit
                  </MenuItem>
                </Menu>
              </TableBody>
            </Table>
          </TableContainer>
          <CustomPagination
            rowsPerPage={10}
            totalSize={totalOrganizationsCount}
            page={page - 1}
            onNextPage={(newPage: number) => {
              setPage(newPage + 1);
              if (
                organizationsList.length < totalOrganizationsCount! &&
                organizationsList.length < (newPage + 1) * rowsPerPage
              ) {
                fetchNextOrganizations(newPage + 1);
              }
            }}
            onPrevPage={(newPage: number) => {
              setPage(newPage + 1);
            }}
          />
        </Box>
      )}
    </>
  );
});

export default OrganizationsList;
