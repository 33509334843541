import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Select,
  MenuItem,
  SelectChangeEvent,
  Stack,
  Typography,
  Grid,
  Tab,
  Tabs,
} from '@mui/material';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import MdEditor from 'react-markdown-editor-lite';
import 'react-markdown-editor-lite/lib/index.css';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import {
  AnnouncementType,
  AnnouncementContentBlock,
  announcementTypeFromJSON,
} from 'protos/common/announcement';
import { Announcement } from 'protos/pb/orby_internal/orby_internal_service';
import CustomTypography, {
  TypographyType,
} from '../../components/core/CustomTypography';
import CustomButton from '../../components/core/CustomButton';
import CustomTextField from '../../components/core/CustomTextField';
import { useStore } from '../../hooks/useStore';
import { toastService } from '../../services/ToastService';
import AnnouncementPreviewDialog from './AnnouncementPreviewDialog';
import AnnouncementDeletionModal from './AnnouncementDeletionModal';
import './AnnouncementDetailPage.css';

interface AnnouncementFormValues {
  name: string;
  description: string;
  type: string;
  isActive: boolean;
  header: string;
  contentBlocks: string[];
}

const AnnouncementDetailPage: React.FC = observer(() => {
  const { announcementId } = useParams();
  const navigate = useNavigate();
  const store = useStore();
  const {
    loadingAnnouncement,
    selectedAnnouncement,
    loadAnnouncementError,
    processingAnnouncement,
    processAnnouncementError,
    createdAnnouncement,
    updatedAnnouncement,
    getAnnouncement,
    createAnnouncement,
    updateAnnouncement,
    deleteAnnouncement,
    resetAnnouncementDetail,
    clearErrors,
  } = store.announcementStore;
  const [selectedPageIndex, setSelectedPageIndex] = React.useState(0);
  const [creationTriggered, setCreationTriggered] = useState(false);
  const [updateTriggered, setUpdateTriggered] = useState(false);
  const [deletionTriggered, setDeletionTriggered] = useState(false);
  const [isPreviewDialogOpen, setIsPreviewDialogOpen] = useState(false);
  const [isDeletionModalOpen, setIsDeletionModalOpen] = useState(false);

  useEffect(() => {
    clearErrors();
    resetAnnouncementDetail();
    if (announcementId && announcementId !== 'create') {
      getAnnouncement(announcementId);
    }
  }, [announcementId]);

  useEffect(() => {
    if (loadAnnouncementError) {
      toastService.showError(
        `Failed to fetch announcement: ${loadAnnouncementError}`,
      );
      resetAnnouncementDetail();
      navigate(-1);
    }
  }, [loadAnnouncementError]);

  useEffect(() => {
    if (processAnnouncementError) {
      if (creationTriggered) {
        toastService.showError(
          `Failed to create announcement: ${processAnnouncementError}`,
        );
      }
      if (updateTriggered) {
        toastService.showError(
          `Failed to update announcement: ${processAnnouncementError}`,
        );
      }
      if (deletionTriggered) {
        toastService.showError(
          `Failed to delete announcement: ${processAnnouncementError}`,
        );
      }
    }
  }, [processAnnouncementError]);

  useEffect(() => {
    if (createdAnnouncement && creationTriggered) {
      toastService.showSuccess('Announcement is created successfully');
      setTimeout(() => {
        navigate(-1);
      }, 1000);
    }
  }, [createdAnnouncement]);

  useEffect(() => {
    if (updatedAnnouncement && updateTriggered) {
      toastService.showSuccess('Announcement is updated successfully');
      setTimeout(() => {
        navigate(-1);
      }, 1000);
    }
  }, [updatedAnnouncement]);

  useEffect(() => {
    if (deletionTriggered && !processAnnouncementError) {
      toastService.showSuccess('Announcement is deleted successfully');
      setTimeout(() => {
        navigate(-1);
      }, 1000);
    }
  }, [deletionTriggered, processAnnouncementError]);

  const isNewAnnouncement = selectedAnnouncement === undefined;

  const onSubmit = (values: AnnouncementFormValues) => {
    if (isNewAnnouncement) {
      const req = Announcement.create({
        displayName: values.name,
        description: values.description,
        type: announcementTypeFromJSON(+values.type),
        isActive: values.isActive,
        header: {
          data: values.header,
        },
        contentBlocks: values.contentBlocks.map((cb) => {
          return AnnouncementContentBlock.create({
            body: {
              data: cb,
            },
          });
        }),
      });
      createAnnouncement(req);
      setCreationTriggered(true);
    } else {
      const req = Announcement.create({
        id: selectedAnnouncement?.id,
        displayName: values.name,
        description: values.description,
        type: announcementTypeFromJSON(+values.type),
        header: {
          data: values.header,
        },
        contentBlocks: values.contentBlocks.map((cb) => {
          return AnnouncementContentBlock.create({
            body: {
              data: cb,
            },
          });
        }),
      });
      updateAnnouncement(req);
      setUpdateTriggered(true);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: selectedAnnouncement?.displayName ?? '',
      description: selectedAnnouncement?.description ?? '',
      isActive: selectedAnnouncement?.isActive || false,
      type: (
        selectedAnnouncement?.type ?? AnnouncementType.RELEASE_NOTES
      ).toString(),
      header: selectedAnnouncement?.header?.data ?? '',
      contentBlocks: selectedAnnouncement?.contentBlocks?.map(
        (b) => b.body?.data ?? '',
      ) ?? [''],
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required('Announcement name is required.')
        .max(100, 'Announcement name must be at most 100 characters.'),
      header: Yup.string().required('Header content is required.'),
      contentBlocks: Yup.array().of(
        Yup.string().required('Page content is required.'),
      ),
    }),
    onSubmit: onSubmit,
    enableReinitialize: true,
  });

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    values,
  } = formik;

  const handleDeleteAnnouncement = () => {
    if (!selectedAnnouncement?.id) {
      return;
    }
    deleteAnnouncement(selectedAnnouncement.id);
    setDeletionTriggered(true);
  };

  const handlePreview = () => {
    if (!selectedAnnouncement) {
      return;
    }
    selectedAnnouncement.header = { data: values.header };
    selectedAnnouncement.contentBlocks = values.contentBlocks.map((cb) => {
      return AnnouncementContentBlock.create({
        body: {
          data: cb,
        },
      });
    });
    setIsPreviewDialogOpen(true);
  };

  const createNewContentBlock = () => {
    const newContentBlocks = [...values.contentBlocks];
    newContentBlocks.push('');
    setFieldValue('contentBlocks', newContentBlocks);
    setSelectedPageIndex(newContentBlocks.length - 1);
  };

  const deleteContentBlock = (index: number) => () => {
    if (values.contentBlocks.length <= 1) {
      return;
    }
    const newContentBlocks = [...values.contentBlocks];
    newContentBlocks.splice(index, 1);
    setFieldValue('contentBlocks', newContentBlocks);
    setSelectedPageIndex(0);
  };

  const renderContentPagesError = () => {
    if (Array.isArray(errors.contentBlocks)) {
      for (let i = 0; i < errors.contentBlocks.length; i++) {
        if (errors.contentBlocks[i]) {
          return (
            <Typography variant='body2' color='error'>
              {`Page ${i + 1}: ${errors.contentBlocks[i]}`}
            </Typography>
          );
        }
      }
    }
    return null;
  };

  const goBack = () => {
    navigate(-1);
  };

  function customMarkdownLink({ ...props }) {
    return (
      <a {...props} target='_blank'>
        {props.children}
      </a>
    );
  }

  if (loadingAnnouncement) {
    return (
      <Box sx={{ display: 'flex', pt: '60px', justifyContent: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        bgcolor: '#F6F8FC',
        paddingX: '50px',
        paddingTop: '50px',
        minWidth: '750px',
      }}
    >
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        spacing={2}
      >
        <CustomTypography
          component={'h1'}
          typographyType={TypographyType.Header2}
          sx={{ marginBottom: '10px', marginTop: '20px' }}
        >
          {isNewAnnouncement ? 'Create Announcement' : 'Update Announcement'}
        </CustomTypography>
        <ButtonGroup
          disableElevation
          variant='outlined'
          size='small'
          disabled={loadingAnnouncement || processingAnnouncement}
        >
          <Button onClick={handlePreview} disabled={processingAnnouncement}>
            Preview
          </Button>
          {!isNewAnnouncement && (
            <Button
              onClick={() => {
                setIsDeletionModalOpen(true);
              }}
              disabled={processingAnnouncement}
              color='error'
            >
              Delete
            </Button>
          )}
        </ButtonGroup>
      </Stack>
      <FormikProvider value={formik}>
        <Form
          id='announcement-form'
          autoComplete='off'
          noValidate
          onSubmit={handleSubmit}
        >
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={'auto'}>
              <Box sx={{ marginLeft: '14px', marginTop: '11px' }}>
                <CustomTextField
                  label='Name'
                  size='small'
                  value={values.name}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                  hasDescription={true}
                  autoFocus
                  extraProps={getFieldProps('name')}
                />
              </Box>
            </Grid>
            <Grid item xs={'auto'}>
              <Box sx={{ marginLeft: '14px', marginTop: '11px' }}>
                <CustomTextField
                  label='Description'
                  size='large'
                  value={values.description}
                  error={Boolean(touched.description && errors.description)}
                  helperText={touched.description && errors.description}
                  extraProps={getFieldProps('description')}
                />
              </Box>
            </Grid>
            <Grid item xs={'auto'}>
              <Box
                sx={{
                  marginLeft: '14px',
                  marginTop: '11px',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <CustomTypography
                  component={'span'}
                  typographyType={TypographyType.Label}
                  sx={{ marginBottom: '8px' }}
                >
                  Type
                </CustomTypography>
                <Select
                  value={values.type}
                  onChange={(event: SelectChangeEvent) => {
                    setFieldValue('type', event.target.value);
                  }}
                  size='small'
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  <MenuItem value={'1'}>RELEASE NOTES</MenuItem>
                </Select>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  marginLeft: '14px',
                  marginTop: '11px',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <CustomTypography
                  component={'span'}
                  typographyType={TypographyType.Label}
                >
                  Header
                </CustomTypography>
                <MdEditor
                  value={values.header}
                  style={{ minHeight: '120px' }}
                  htmlClass='custom-markdown'
                  renderHTML={(text) => (
                    <ReactMarkdown
                      remarkPlugins={[remarkGfm]}
                      rehypePlugins={[rehypeRaw]}
                      components={{
                        a: customMarkdownLink,
                      }}
                    >
                      {text}
                    </ReactMarkdown>
                  )}
                  onChange={({ text }) => {
                    setFieldValue('header', text);
                  }}
                />
                {touched.header && errors.header && (
                  <Typography variant='body2' color='error'>
                    {errors.header}
                  </Typography>
                )}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                marginLeft={'14px'}
                marginTop={'11px'}
                display={'flex'}
                flexDirection={'column'}
              >
                <CustomTypography
                  component={'span'}
                  typographyType={TypographyType.Label}
                >
                  Pages
                </CustomTypography>
                <Box sx={{ flexGrow: 1, display: 'flex', height: '450px' }}>
                  <Tabs
                    orientation='vertical'
                    variant='scrollable'
                    value={selectedPageIndex}
                    onChange={(_, index) => setSelectedPageIndex(index)}
                    scrollButtons={'auto'}
                  >
                    {values.contentBlocks.map((_, index) => {
                      return (
                        <Tab
                          label={`Page ${index + 1}`}
                          id={`page-tab-${index + 1}`}
                          key={`page-tab-${index + 1}`}
                          aria-controls={`page-tabpanel-${index + 1}`}
                        ></Tab>
                      );
                    })}
                  </Tabs>
                  {values.contentBlocks.map((cb, index) => {
                    return (
                      <div
                        style={{ width: '100%' }}
                        role='tabpanel'
                        hidden={selectedPageIndex !== index}
                        key={`page-tab-${index + 1}`}
                        aria-labelledby={`page-tabpanel-${index + 1}`}
                      >
                        {selectedPageIndex === index && (
                          <Box>
                            <Stack
                              direction='row'
                              justifyContent='space-between'
                              alignItems='center'
                              spacing={2}
                              marginBottom={'8px'}
                            >
                              <Button
                                variant='outlined'
                                onClick={createNewContentBlock}
                                disabled={processingAnnouncement}
                              >
                                Add New Page
                              </Button>
                              <Button
                                variant='outlined'
                                onClick={deleteContentBlock(index)}
                                disabled={
                                  processingAnnouncement ||
                                  values.contentBlocks.length <= 1
                                }
                                color='error'
                              >
                                Delete Page
                              </Button>
                            </Stack>
                            <MdEditor
                              value={cb}
                              style={{ height: '400px', width: '100%' }}
                              htmlClass='custom-markdown'
                              renderHTML={(text) => (
                                <ReactMarkdown
                                  remarkPlugins={[remarkGfm]}
                                  rehypePlugins={[rehypeRaw]}
                                  components={{
                                    a: customMarkdownLink,
                                  }}
                                >
                                  {text}
                                </ReactMarkdown>
                              )}
                              onChange={({ text }) => {
                                const newContentBlocks = [
                                  ...values.contentBlocks,
                                ];
                                newContentBlocks[index] = text;
                                setFieldValue(
                                  'contentBlocks',
                                  newContentBlocks,
                                );
                              }}
                            />
                          </Box>
                        )}
                      </div>
                    );
                  })}
                </Box>
                {touched.contentBlocks &&
                  errors.contentBlocks &&
                  renderContentPagesError()}
              </Box>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>
      <Box
        sx={{
          display: 'flex',
          gap: '25px',
          margin: '16px 0',
          justifyContent: 'end',
        }}
      >
        <CustomButton onClick={() => goBack()} variant='outlined'>
          Cancel
        </CustomButton>
        {isNewAnnouncement && (
          <CustomButton
            form={'announcement-form'}
            type='submit'
            disabled={processingAnnouncement}
            loading={processingAnnouncement}
          >
            Create
          </CustomButton>
        )}
        {!isNewAnnouncement && (
          <CustomButton
            form={'announcement-form'}
            type='submit'
            disabled={processingAnnouncement}
            loading={processingAnnouncement}
          >
            Update
          </CustomButton>
        )}
      </Box>
      <AnnouncementPreviewDialog
        show={isPreviewDialogOpen}
        announcement={selectedAnnouncement}
        handleClose={() => setIsPreviewDialogOpen(false)}
      />
      <AnnouncementDeletionModal
        open={isDeletionModalOpen}
        handleClose={() => setIsDeletionModalOpen(false)}
        onSubmit={handleDeleteAnnouncement}
      />
    </Box>
  );
});

export default AnnouncementDetailPage;
